<template>
  <section class="weblog-categories border-radius15 box-shaddow20 p-3 bg-white mb-3">
    <div class="d-flex border-bottom pt-2 pb-3 pr-2">
      <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.53989 2H7.91989C9.32989 2 10.4599 3.15 10.4599 4.561V7.97C10.4599 9.39 9.32989 10.53 7.91989 10.53H4.53989C3.13989 10.53 1.99989 9.39 1.99989 7.97V4.561C1.99989 3.15 3.13989 2 4.53989 2ZM4.53989 13.4697H7.91989C9.32989 13.4697 10.4599 14.6107 10.4599 16.0307V19.4397C10.4599 20.8497 9.32989 21.9997 7.91989 21.9997H4.53989C3.13989 21.9997 1.99989 20.8497 1.99989 19.4397V16.0307C1.99989 14.6107 3.13989 13.4697 4.53989 13.4697ZM19.46 2H16.08C14.67 2 13.54 3.15 13.54 4.561V7.97C13.54 9.39 14.67 10.53 16.08 10.53H19.46C20.86 10.53 22 9.39 22 7.97V4.561C22 3.15 20.86 2 19.46 2ZM16.08 13.4697H19.46C20.86 13.4697 22 14.6107 22 16.0307V19.4397C22 20.8497 20.86 21.9997 19.46 21.9997H16.08C14.67 21.9997 13.54 20.8497 13.54 19.4397V16.0307C13.54 14.6107 14.67 13.4697 16.08 13.4697Z" fill="#444"/>
      </svg>
      <h6 class="mr-2 fontsize13 weight-bold text-color-444">دسته بندی ها</h6>
    </div>
    <ul class="py-3 px-2">
      <li :class="selectedCategory && selectedCategory.id == category.id ? 'font-weight-bold text-primary active' : ''"
          v-for="(category, index) in $store.state.header.blogCategories" :key="index">
        <router-link v-if="category" :to="'/blogs/category/' + category.id + '/' + category.name">{{ category.name }}</router-link>
        <b-skeleton v-else></b-skeleton>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "categoryList",
  props: {
    selectedCategory: Object
  },
  methods: {

  }
}
</script>

<style scoped>
.weblog-categories ul>li.active::before {
  background-color: #713348;
}
</style>